import React from "react";
import { RotatingLines ,Vortex,ThreeDots} from "react-loader-spinner";

const ButtonLoader = ({
    visible = true,
    height = 30,
    width =30,
    color = "white",
    strokeWidth = 5,
    animationDuration = 0.75,
    ariaLabel = "rotating-lines-loading",
    wrapperStyle = {},
    wrapperClass = "",
  }) => {
    return (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  <ThreeDots
  visible={true}
  height={height}
  width={width}
  color={color}
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
  </div>
    );
  };

export default ButtonLoader
