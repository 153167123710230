import "../css2/all.css";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./nav.css";
import "./accordianmaid.css";
import { MdDashboard, MdSubscriptions } from "react-icons/md";
import Swal from "sweetalert2";
import { MdPermIdentity } from "react-icons/md";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { PermissionContext } from "../lib/PermissionContext";
import { useContext } from "react";
import { GrDocumentVerified } from "react-icons/gr";
import { IoGitPullRequestOutline } from "react-icons/io5";
import { FaLocationArrow, FaUserFriends } from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaHouseUser } from "react-icons/fa";
import { BsDiagram2Fill } from "react-icons/bs";
import { DataContext } from "../lib/DataContext";
import { LanguageContext } from "../lib/LanguageContext";
import { FaChevronDown } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuCalendarClock } from "react-icons/lu";
import { LiaCalendarWeekSolid } from "react-icons/lia";
import { MdCalendarMonth } from "react-icons/md";
import { BsFillBuildingsFill } from "react-icons/bs";
import { AiOutlineSync } from "react-icons/ai";
import { AiOutlineSchedule } from "react-icons/ai";
import { AiOutlineBulb } from "react-icons/ai";
import { AiOutlineAlert } from "react-icons/ai";
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdAnnouncement } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdReport } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { BiCreditCard } from "react-icons/bi";
import { MdPayment } from "react-icons/md";
import axios from "axios";
import { PORT } from "../Api/api";
import SessionManager from "../lib/SessionManger";
import { FaBuilding } from "react-icons/fa";
const AdminNavbar = () => {
  const { language } = useContext(LanguageContext);
  const { permissions } = useContext(PermissionContext);
  const { data } = useContext(DataContext);
  const [activeItemId, setActiveItemId] = useState("");
  const [expanded, setExpanded] = useState(true);
  const { societyDetails } = useContext(DataContext);
  const roleType = JSON.parse(localStorage.getItem("role"));
  const location = useLocation();
  const navigate = useNavigate();
  const handleRegularEntries = (id) => {
    setActiveItemId(id);
    navigate(`/admin/regular/${id}`);
  };
  const SocietyUserId = JSON.parse(localStorage.getItem("roleId"));
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .post(`${PORT}checkIsActiveUser`, { SocietyUserId })
        .then((response) => {
          // console.log(response.data.active);
          let token = response.data.token;
          if (response.data.active) {
            localStorage.clear();
            Swal.fire("Error !", "Your account has been Blocked.", "error");
            setTimeout(() => {
              window.location.href = "/login"; // Redirect to dashboard
            }, 1500);
          }
        });
    }, 10000); // Poll every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  // Set societyLogo Functionlaity
 
  //Calender Functionality
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isAttendanceActive, setIsAttendanceActive] = useState(false);
  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
    setIsAttendanceActive(true);
  };
  const handleDropdownItemClick = (e) => {
    e.stopPropagation();
  };
  React.useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("/admin/calender")) {
      setIsSubmenuOpen(true);
    } else {
      setIsSubmenuOpen(false);
    }
  }, [location]);

  const handleClick = (id) => {
    setActiveItemId(id);
    handleRegularEntries(id);
  };
  // function lock
  useEffect(() => {
    const currentPath = window.location.pathname; // Get current URL path
    // Check if the current path matches either of the specified paths
    if (
      currentPath === "/admin/viewAffiliateBillingDetails" ||
      currentPath.startsWith("/admin/viewAffiliateSocietyTransactions/")
    ) {
    } else {
      // If path doesn't match, remove the selectedId from localStorage
      localStorage.removeItem("selectedId");
    }
  }, [window.location.pathname]); // Depend on the URL path change
  //Check the Token is Expire Or Not
  useEffect(() => {
    const checkTokenExpiration = () => {
      const data = JSON.parse(localStorage.getItem("data"));

      if (data) {
        const { token, expiresAt } = data;
        const now = new Date().getTime();

        if (now >= expiresAt) {
          localStorage.removeItem("data");
          Swal.fire({
            icon: "warning",
            title: "Session Expired",
            text: "Your session has expired due to inactivity. Please log in again.",
            confirmButtonText: "OK",
          }).then(() => {
            localStorage.clear();
            navigate("/login");
          });
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000);

    return () => clearInterval(interval);
  }, [navigate]);
  // correct logic
  // useEffect(() => {
  //   const resetSession = () => {
  //     const now = new Date().getTime();
  //     localStorage.setItem("lastActivity", now); // Update last activity on user interaction
  //   };
  //   const checkTokenExpiration = () => {
  //     const token = JSON.parse(localStorage.getItem("data"));
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const lastLogout = localStorage.getItem("lastLogout");
  //     const now = new Date().getTime();
  //     const TIMEOUT_DURATION = 3 * 60 * 60 * 1000; // 3 hours in milliseconds for better testing
  //     // const TIMEOUT_DURATION = 25 * 1000; // 25 seconds in milliseconds

  //     if (token) {
  //       // If it's the first login (no lastLogout value), handle it differently
  //       if (!lastLogout) {
  //         // If there is no `lastLogout`, consider it as the first login, so no timeout.
  //         localStorage.setItem("lastLogout", now); // Set lastLogout on first login
  //       }

  //       // Check if last activity or last logout time exceeds the inactivity duration
  //       if (
  //         (lastActivity && now - parseInt(lastActivity) >= TIMEOUT_DURATION) ||
  //         (lastLogout && now - parseInt(lastLogout) >= TIMEOUT_DURATION)
  //       ) {
  //         expireSession(); // Call the expire session function
  //       }
  //     }
  //   };
  //   const expireSession = () => {
  //     localStorage.removeItem("data");
  //     localStorage.removeItem("lastActivity");
  //     localStorage.removeItem("lastLogout");
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Session Expired",
  //       text: "Your session has expired due to inactivity. Please log in again.",
  //       confirmButtonText: "OK",
  //     }).then(() => {
  //       localStorage.clear();
  //       navigate("/affiliateLogin"); // Redirect to login after session expiration
  //     });
  //   };

  //   // Set lastActivity on page load if not already set
  //   if (!localStorage.getItem("lastActivity")) {
  //     localStorage.setItem("lastActivity", new Date().getTime());
  //   }

  //   // Listen for user activity and reset session timer
  //   window.addEventListener("click", resetSession);
  //   window.addEventListener("keydown", resetSession);
  //   window.addEventListener("scroll", resetSession);
  //   window.addEventListener("mousemove", resetSession);

  //   // Store logout time when tab is closed
  //   const handleBeforeUnload = () => {
  //     localStorage.setItem("lastLogout", new Date().getTime());
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Check token expiration every minute (for quicker detection during development)
  //   const interval = setInterval(checkTokenExpiration, 60000);

  //   return () => {
  //     clearInterval(interval);
  //     window.removeEventListener("click", resetSession);
  //     window.removeEventListener("keydown", resetSession);
  //     window.removeEventListener("scroll", resetSession);
  //     window.removeEventListener("mousemove", resetSession);
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [navigate]);
  return (
    <>
      <aside
        className="sidenav bg-white navbar navbar-vertical  border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 right-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a className="navbar-brand m-0 text-center" target="_blank">
            {roleType === 1 || roleType === 2 ? (
              <div className="guard_logo_div ">
                <img src="/newLogoGuardx.png" alt="" className="" />
              </div>
            ) : (
              <div className="society_logo_div">
                <img
                  src={`/${societyDetails?.societyLogo?.replace(
                    "public/",
                    ""
                  )}`}
                  alt=""
                  className="society-logo-on-nav"
                />

                <b>{language === "hindi" ? "  Welcome" : "स्वागत"}</b>
              </div>
            )}
            <span className="ms-1 font-weight-bold"></span>
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="side-nav-main-div">
          <ul className="navbar-nav">
            {/* dashboard */}
            <li className="nav-item">
              <NavLink className="nav-link " to="/admin/dashboard">
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="nav-link-text text-start">
                  <MdDashboard className="icon_font_size" />
                  <Tooltip
                    title={language === "hindi" ? " Dashboard" : " डैशबोर्ड"}
                    placement="right"
                  >
                    <span className="nav-module-right">
                      &nbsp; {language === "hindi" ? " Dashboard" : " डैशबोर्ड"}
                    </span>
                  </Tooltip>
                </span>
              </NavLink>
            </li>
            {/* society */}
            {permissions[6]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/society-details">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text ">
                    <BsFillBuildingsFill className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? " Society" : "सोसाइटी"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp; {language === "hindi" ? " Society" : "सोसाइटी"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* corporate */}
            {/* {roleType === 1 && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/viewCorporateUser">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text ">
                    <FaBuilding className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? "Corporate" : "कॉर्पोरेट"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi" ? "Corporate" : "कॉर्पोरेट"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )} */}
            {/* regulsr entries */}
            {permissions[0]?.actions.module && (
              <li className="nav-item mt--3">
                <div>
                  {
                    <Accordion
                      style={{ width: "100%" }}
                      expanded={data.length > 0 ? expanded : ""}
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                      onChange={handleToggle}
                      // className="accordian_style_setting"
                    >
                      <AccordionSummary
                        expandIcon={
                          <FaChevronDown className="accordion-icon" />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                          <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                        </div>
                        <span className="nav-link-text change_color_regular_side text-start">
                          <GrDocumentVerified />
                          &nbsp;&nbsp;
                          <Tooltip
                            title={
                              language === "hindi"
                                ? "Regular Entries"
                                : "नियमित प्रविष्टियाँ"
                            }
                            placement="right"
                          >
                            <span className="nav-module-right">
                              &nbsp;{" "}
                              {language === "hindi"
                                ? "Regular Entries"
                                : "नियमित प्रविष्टियाँ"}
                            </span>
                          </Tooltip>
                        </span>
                      </AccordionSummary>
                      <div className="regular_title_content">
                        {
                          <div
                            className={
                              data.length > 15
                                ? "dropDown_regular_title"
                                : "regular_ul"
                            }
                          >
                            <ul className="ul_navlink">
                              {data.map((item) => {
                                if (item?.entryType === "Regular") {
                                  return (
                                    <div
                                      key={item._id}
                                      className="regular_entry_div"
                                    >
                                      <li
                                        onClick={() =>
                                          handleRegularEntries(item._id)
                                        }
                                        className={`${
                                          item._id === activeItemId
                                            ? "active_item"
                                            : ""
                                        }`}
                                      >
                                        &nbsp; &nbsp;&nbsp;
                                        {item?.icon?.length > 0 ? (
                                          <img
                                            src={`/${item.icon.replace(
                                              "public/",
                                              ""
                                            )}`}
                                            alt=""
                                            height="30px"
                                            className="img-regular-section"
                                          />
                                        ) : (
                                          <div className="entry_placeholder_for_sidenavbar">
                                            <div className="logo-regular-section">
                                              <h6>
                                                {item.titleEnglish
                                                  .split(" ")[0]
                                                  .substring(0, 1)
                                                  .toUpperCase()}
                                              </h6>
                                            </div>
                                          </div>
                                        )}
                                        <Tooltip
                                          title={item.titleEnglish}
                                          placement="right"
                                        >
                                          {item.titleEnglish.length > 20
                                            ? item.titleEnglish.substring(
                                                0,
                                                19
                                              ) + "..."
                                            : item.titleEnglish}
                                        </Tooltip>
                                      </li>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </ul>
                          </div>
                          // )
                        }
                      </div>
                    </Accordion>
                  }
                </div>
              </li>
            )}
            {/* Guest Entries Requests */}
            {permissions[1]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/home">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text ms--2 text-start">
                    <IoGitPullRequestOutline className="icon_font_size" />
                    <Tooltip
                      title={
                        language === "hindi"
                          ? "Guest Entries Requests"
                          : " अतिथि प्रविष्टि अनुरोध"
                      }
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi"
                          ? "Guest Entries Requests"
                          : " अतिथि प्रविष्टि अनुरोध"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Type of Entries */}
            {permissions[2]?.actions.module && (
              //  (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/entry-type">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>

                  <span className="nav-link-text ms--2 text-start">
                    <AiOutlineSchedule className="icon_font_size" />
                    <Tooltip
                      title={
                        language === "hindi"
                          ? "Type of Entries"
                          : "प्रविष्टियों का प्रकार"
                      }
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi"
                          ? "Type of Entries"
                          : "प्रविष्टियों का प्रकार"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* //Purpose of Occasional */}
            {permissions[3]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/purpose-type">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <AiOutlineBulb className="icon_font_size" />
                    <Tooltip
                      title={
                        language === "hindi"
                          ? " Purpose of Occasional"
                          : "अवसर का उद्देश्य"
                      }
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi"
                          ? " Purpose of Occasional"
                          : "अवसर का उद्देश्य"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* //Subscription */}
            {permissions[12]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/viewSubscription">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <MdSubscriptions className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? "Subscription" : "सदस्यता"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi" ? "Subscription" : "सदस्यता"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Affiliate User */}
            {permissions[13]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/viewAffiliateUser">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <FaUserFriends className="icon_font_size" />
                    <Tooltip
                      title={
                        language === "hindi"
                          ? "Affiliate User"
                          : "साझीदार उपयोगकर्ता"
                      }
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi"
                          ? "Affiliate User"
                          : "साझीदार उपयोगकर्ता"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Affiliate Billing and Invoice */}
            {permissions[15]?.actions.module && (
              <li className="nav-item">
                <NavLink
                  className="nav-link "
                  to="/admin/viewAffiliateBillingDetails"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <MdPayment className="icon_font_size" />
                    <Tooltip
                      title={
                        language === "hindi"
                          ? "Affiliate Billing "
                          : "संबद्ध बिलिंग   "
                      }
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi"
                          ? "Affiliate Billing"
                          : "संबद्ध बिलिंग  "}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* House List */}
            {permissions[4]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/house-data">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <AiOutlineHome className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? "House List" : "घर सूची"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp; {language === "hindi" ? "House List" : "घर सूची"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Attendance */}
            {permissions[9]?.actions.module && (
              <li className="nav-item">
                <div
                  className="nav-link"
                  onClick={toggleSubmenu}
                  style={{ cursor: "pointer" }}
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  text-start">
                    <FaRegCalendarAlt className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? "Attendance" : "उपस्थिति "}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi" ? "Attendance" : "उपस्थिति "}
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </li>
            )}

            {isSubmenuOpen && (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={handleDropdownItemClick}
                    to="/admin/calender/daily"
                  >
                    <Tooltip
                      title={language === "english" ? " दैनिक " : "Daily "}
                      placement="right"
                    >
                      <span className="nav-link-text ms-5">
                        <LuCalendarClock className="icon_font_size" />{" "}
                        &nbsp;&nbsp;{" "}
                        {language === "english" ? " दैनिक " : "Daily "}
                      </span>
                    </Tooltip>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={handleDropdownItemClick}
                    to="/admin/calender/weekly"
                  >
                    <Tooltip
                      title={language === "english" ? "  साप्ताहिक " : "Weekly"}
                      placement="right"
                    >
                      <span className="nav-link-text ms-5">
                        <LiaCalendarWeekSolid className="icon_font_size" />
                        &nbsp;&nbsp;{" "}
                        {language === "english" ? "  साप्ताहिक " : "Weekly"}
                      </span>
                    </Tooltip>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    onClick={handleDropdownItemClick}
                    to="/admin/calender/monthly"
                  >
                    <Tooltip
                      title={language === "english" ? "मासिक" : "Monthly"}
                      placement="right"
                    >
                      <span className="nav-link-text ms-5">
                        <MdCalendarMonth className="icon_font_size" />{" "}
                        &nbsp;&nbsp;{" "}
                        {language === "english" ? "मासिक" : "Monthly"}
                      </span>
                    </Tooltip>
                  </NavLink>
                </li>
              </>
            )}
            {/* Announcement */}
            {permissions[10]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/viewAnnouncement">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <FaRegBell className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? "Announcements" : "घोषणा"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi" ? "Announcements" : "घोषणा"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Complaints */}
            {permissions[11]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/viewComplaints">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <FaExclamationTriangle className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? "Complaints" : "शिकायतें"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{" "}
                        {language === "hindi" ? "Complaints" : "शिकायतें"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Users */}
            {permissions[7]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/showUser">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <AiOutlineUserAdd className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? " Users" : "उपयोगकर्ता"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{language === "hindi" ? " Users" : "उपयोगकर्ता"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* //billing module */}
            {permissions[14]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/viewBillingDetails">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <FaMoneyBillWave className="icon_font_size" />
                    <Tooltip
                      title={
                        language === "hindi"
                          ? " Billing and Invoice"
                          : "बिलिंग और चालान"
                      }
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;
                        {language === "hindi"
                          ? " Billing and Invoice"
                          : "बिलिंग और चालान"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
            {/* roles */}
            {permissions[5]?.actions.module && (
              <li className="nav-item">
                <NavLink className="nav-link " to="/admin/readRoles">
                  <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="nav-link-text  ms--2 text-start">
                    <AiOutlineUserSwitch className="icon_font_size" />
                    <Tooltip
                      title={language === "hindi" ? " Roles" : "भूमिकाएँ"}
                      placement="right"
                    >
                      <span className="nav-module-right">
                        &nbsp;{language === "hindi" ? " Roles" : "भूमिकाएँ"}
                      </span>
                    </Tooltip>
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default AdminNavbar;
