import React, { useContext, useEffect } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LanguageContext } from "../../../lib/LanguageContext";
import { ThreeCircles } from "react-loader-spinner";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { PORT } from "../../../Api/api";

import Swal from "sweetalert2";
import { RxCross1, RxCross2 } from "react-icons/rx";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: 400,
  p: 4,
  zIndex: "10000000",
};
const ViewVehicleList = ({ data }) => {
  const { language } = useContext(LanguageContext);
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("")
  const vehiclesArray =
    data && data[0] && data[0].vehicles ? data[0].vehicles : [];

  //   handle vehicle Functionality
  const handleDelete = async (ownerId, vehicleIndex) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",

      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.delete(
        `${PORT}/deleteVehicle/${ownerId}/${vehicleIndex}`
      );
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error deleting vehicle:", error);
      toast.error(
        error.response?.data?.error ||
        "An error occurred while deleting the vehicle"
      );
    }
  };
  const handleClose = () => {
    setOpen(false);

  };
  const handleOpen = (images) => {
    setOpen(true);
    setImage(images);
  };
  //function lock
  useEffect(() => {
    setTimeout(() => {
      setLoadingPermission(false);
    }, 4000);
  }, []);
  return (
    <div>
      <div>
        <div className="list-view-vehicle-user-heading">
          {language === "english" ? "वाहन सूची देखें" : "View Vehicle List "}
        </div>

        <div className="list-view-vehicle-user">
          {loadingPermission ? (
            <div className="three_circle_loader_vehicle_view">
              <ThreeCircles
                visible={true}
                height={90}
                width={90}
                color="#5e72e4"
                ariaLabel="three-circles-loading"
              />
            </div>
          ) : (
            <table className="vehicle-table">
              <thead>
                <tr>
                  <th>
                    {" "}
                    {language === "english" ? "वाहन सूची देखें" : "Vehicle ID "}
                  </th>
                  <th>
                    {" "}
                    {language === "english"
                      ? "वाहन सूची देखें"
                      : "Vehicle Type "}
                  </th>
                  <th>
                    {" "}
                    {language === "english" ? "वाहन प्रकार" : "Vehicle Number "}
                  </th>
                  <th>
                    {" "}
                    {language === "english" ? "वाहन संख्या" : "Vehicle Image "}
                  </th>
                  <th> {language === "english" ? "क्रिया" : "Action  "}</th>
                </tr>
              </thead>

              <tbody>
                {vehiclesArray.length > 0 ? (
                  [...vehiclesArray].reverse().map((item, index) => (
                    <tr key={index}>
                      <td>{`Vehicle ${vehiclesArray.length - index}`}</td>
                      <td>
                        {item.type === "Bike"
                          ? "Two-Wheelers"
                          : item.type === "Car"
                            ? "Four-Wheelers"
                            : item.type === "Truck" ||
                              item.type === "Bus" ||
                              item.type === "Lorry"
                              ? "Heavy Vehicles (Truck, Bus, Lorry)"
                              : "Not Added"}
                      </td>

                      <td>{item.number ? item.number : "Not Added"}</td>
                      <td>
                        {item.image ? (
                          <img
                            onClick={() => handleOpen(item.image)}
                            src={`/${item.image.replace("public/", "")}`}
                            alt=""
                            className="vehicle-image"
                          />
                        ) : (
                          "Not Added"
                        )}
                      </td>
                      <td>
                        {" "}
                        <button
                          onClick={() => handleDelete(data[0]._id, index)}
                          className="dlt-btn"
                        >
                          <MdDelete
                            data-toggle="tooltip"
                            className="eyes_view"
                            data-placement="top"
                            title={
                              language === "hindi" ? "Click to Delete" : "हटाएं"
                            }
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No vehicles available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="details-dialog"
      >
        <Box sx={style}>
          {/* Images View */}
          <br />
          <div className="">
            {

              image ? <img

                src={`/${image.replace("public/", "")}`}
                alt=""
                className="modal-vehicle-image"
              /> : <div className="no-images-avaliable">No images available</div>
            }
          </div>
          <div className="cross_button">
            <RxCross2 onClick={handleClose}></RxCross2>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewVehicleList;
